import React, { useState } from "react";
import { connect } from "react-redux";
import { Table, TableColumn, TableDrawer, TableDrawerColumn, Typography } from "common";
import { TransactionDetailsTableCell } from "features/DeliveredOrdersTable/TransactionDetailsTableCell";
import { getMilliTime, subDays } from "core/utils/dateTime";
import { USER_CONTROLS } from "core/config/controls";
import { getControlValue } from "core/store/selectors/UserSelectors";
import { ShippingDeliveryDatesTableCell } from "common/TableCells";
import { formatUSDCurrency } from "core/utils/formatters";
import { factoringFilterKeys } from "core/config/filterKeys";
import { getActiveOrdersAndTypeByCategory } from "core/store/selectors/OrdersSelectors";
import {
  ORDERS_CATEGORY_PAYMENTS_RECEIVED,
  ORDERS_TYPE_PAYMENTS_RECEIVED
} from "core/utils/constCapsule";
import {
  CommentsIndicatorAction,
  ImagesIndicatorAction,
  EmailIndicatorAction
} from "features/IndicatorActions";
import { IndicatorActions } from "common/TableCells/IndicatorActions";
import { useMountEffect } from "lib/util";
import { fetchOrders } from "core/store/actions/OrdersActions";
import { OrderDetailsTableCell } from "./OrderDetailsTableCell";
import { InlineOrderDetailsDrawer } from "./DetailsDrawer";
import { PayDetailsTableCell } from "./PayDetailsTableCell";
import { DaysFilter } from "./DaysFilter";
import { usePDFFormatter, useXLSFormatter } from "./hooks";

export function PaymentsReceivedOrdersTablePresentation({
  isFetching,
  orders,
  defaultDays,
  ordersType,
  dispatchFetchOrders
}) {
  const [days, setDays] = useState(defaultDays);
  const pdfArgs = usePDFFormatter("payments-received", orders);
  const xlsArgs = useXLSFormatter("payments-received", orders);

  useMountEffect(() => {
    dispatchFetchOrders(ORDERS_CATEGORY_PAYMENTS_RECEIVED, ORDERS_TYPE_PAYMENTS_RECEIVED);
  });

  const filteredOrders = orders
    ? orders.filter(({ check: { milli } = {} }) => milli > getMilliTime(subDays(days)))
    : [];

  return (
    <Table
      loading={!orders && isFetching}
      id="paymentsReceivedTable"
      data={filteredOrders}
      defaultSortKey="check.date"
      defaultSortOrder="desc"
      stickyHeader
      emptyText="No Payments Received Orders Available for Display"
      filterKeys={factoringFilterKeys}
      options={{
        onPDF: () => pdfArgs,
        onXLS: () => xlsArgs
      }}
      renderToolbarRight={() => <DaysFilter onChange={setDays} value={days} />}
    >
      <TableColumn
        label="Order Number"
        sortKey="order_id"
        minWidth={70}
        Component={<OrderDetailsTableCell label="BOL #" />}
      />
      <TableColumn
        label="MC Number"
        sortKey="mcNumber"
        Component={({ mcNumber }) => <Typography component="div" value={mcNumber} />}
      />
      <TableColumn
        label="Carrier Information"
        sortKey="carrierName"
        minWidth={70}
        Component={({ carrierName }) => <Typography component="div" value={carrierName} />}
      />
      <TableColumn
        label="Carrier Invoice #"
        sortKey="invoice.id"
        minWidth={100}
        Component={<TransactionDetailsTableCell dataKey="invoice" label="Invoice Received Date" />}
      />
      <TableColumn
        label="Shipping Date"
        sortKey="shipDate.milli"
        minWidth={80}
        Component={<ShippingDeliveryDatesTableCell />}
      />
      <TableColumn
        label="Check #"
        sortKey="check.date"
        minWidth={80}
        Component={<TransactionDetailsTableCell dataKey="check" label="Check Date" />}
      />
      <TableColumn
        label="Pay"
        sortKey="totalPay"
        minWidth={80}
        Component={<PayDetailsTableCell label="Advances/Deductions" payType="total" />}
      />
      <TableColumn
        label="Net Pay"
        sortKey="netPay"
        minWidth={80}
        Component={({ netPay }) => (
          <Typography component="div" value={formatUSDCurrency(netPay) || "N/A"} />
        )}
      />
      <TableColumn
        label="Actions"
        minWidth={80}
        Component={
          <IndicatorActions>
            <EmailIndicatorAction />
            <CommentsIndicatorAction index={0} />
            <ImagesIndicatorAction index={1} />
          </IndicatorActions>
        }
      />
      <TableDrawer>
        <TableDrawerColumn
          fullWidth
          Component={<InlineOrderDetailsDrawer ordersType={ordersType} />}
        />
      </TableDrawer>
    </Table>
  );
}

const mapStateToProps = (state) => ({
  defaultDays: getControlValue(state, USER_CONTROLS.FACTORING_COMPANY_SEARCH_DAYS),
  ...getActiveOrdersAndTypeByCategory(state, ORDERS_CATEGORY_PAYMENTS_RECEIVED)
});

const mapDispatchToProps = {
  dispatchFetchOrders: fetchOrders
};

export const PaymentsReceivedOrdersTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentsReceivedOrdersTablePresentation);
