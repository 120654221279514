import { mapModelDataFromAPI } from "core/utils/mapper";
import { convertMilliToDateTimeObject } from "core/utils/formatters";

const convertMilli = (milli) => convertMilliToDateTimeObject(milli, "MM/dd/yyyy");

const keyMap = {
  order_number: "order_id",
  bol: "bolNum",
  ship_date: "shipDate",
  delivery_date: "deliveryDate",
  payee_id: "carrierName",
  total_pay: "totalPay",
  net_pay: "netPay",
  carrier_invoice: "invoice.id",
  carrier_date: "invoice",
  check_number: "check.id",
  check_date: "check",
  carrier_name: "carrierName",
  advance_deduction: "advanceDeduction",
  settle_hist_id: "settleHistId",
  movement_id: "movementId",
  mc_number: "mcNumber",
  comments_count: "commentsCount"
};

const valueFormatters = {
  ship_date: convertMilli,
  delivery_date: convertMilli,
  carrier_date: convertMilli,
  check_date: convertMilli
};

export function mapPaymentsReceivedOrders(orders) {
  // console.log(mapModelDataFromAPI(orders, keyMap, valueFormatters));
  return mapModelDataFromAPI(orders.data, keyMap, valueFormatters);
}
